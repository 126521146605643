import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Layout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/home.vue"),
      },
      {
        path: "room",
        name: "room",
        component: () => import("@/views/room.vue"),
      },{
        path: "adminUser",
        name: "adminUser",
        component: () => import("@/views/adminUser.vue"),
      },{
        path: "user",
        name: "user",
        component: () => import("@/views/user.vue"),
      },{
        path: "order",
        name: "order",
        component: () => import("@/views/order.vue"),
      },{
        path: "volumeAttributes",
        name: "volumeAttributes",
        component: () => import("@/views/volumeAttributes.vue"),
      },{
        path: "mtVolumeNo",
        name: "mtVolumeNo",
        component: () => import("@/views/mtVolumeNo.vue"),
      },{
        path: "refund",
        name: "refund",
        component: () => import("@/views/refund.vue"),
      }, {
        path: "userVolume",
        name: "userVolume",
        component: () => import("@/views/userVolume.vue"),
      }, {
        path: "balancePay",
        name: "balancePay",
        component: () => import("@/views/balancePay.vue"),
      }, {
        path: "config",
        name: "config",
        component: () => import("@/views/config.vue"),
      }, {
        path: "roomClean",
        name: "roomClean",
        component: () => import("@/views/roomClean.vue"),
      }
    ],
  },
  {
    path: "/clean",
    component: () => import("@/layout/index1.vue"),
    redirect: "/clean/home",
    children: [
      {
        path: "home",
        name: "cleanHome",
        component: () => import("@/views/clean/home.vue"),
      },
      {
        path: "cleaninfo",
        name: "cleanInfo",
        component: () => import("@/views/clean/cleaninfo.vue"),
      },
      {
        path: "addclean",
        name: "addclean",
        component: () => import("@/views/clean/addclean.vue"),
      },
      {
        path: "login",
        name: "login",
        component: () => import("@/views/clean/login.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/:path(.*)",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
