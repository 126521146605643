<template>
  <div class="layout">
    <el-container>
      <el-aside width="200px">
        <!-- LOGOBOX -->
        <div class="logoBox">
          <!-- <img src="../assets/logo.png" alt="logo" /> -->
          <span>{{ title }}</span>
        </div>

        <!-- 导航栏 -->
        <el-menu :default-active="activeIndex" router>
          <el-menu-item index="/">
            <i class="el-icon-menu"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="/adminUser">
            <i class="el-icon-s-custom"></i>
            <span slot="title">账号管理</span>
          </el-menu-item>
          <el-menu-item index="/room">
            <i class="el-icon-mobile"></i>
            <span slot="title">房间管理</span>
          </el-menu-item>
          <el-menu-item index="/user">
            <i class="el-icon-user"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item index="/order">
            <i class="el-icon-notebook-1"></i>
            <span slot="title">订单管理</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-s-ticket"></i>
              <span>卡卷管理</span>
            </template>
            <el-menu-item index="/mtVolumeNo">
              <!-- <i class="el-icon-cpu"></i> -->
              <span slot="title">卡卷管理</span>
            </el-menu-item>
            <el-menu-item index="/volumeAttributes">
              <!-- <i class="el-icon-cpu"></i> -->
              <span slot="title">卡卷属性管理</span>
            </el-menu-item>
            <el-menu-item index="/userVolume">
              <!-- <i class="el-icon-coordinate"></i> -->
              <span slot="title">用户卡卷管理</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/refund">
            <i class="el-icon-coordinate"></i>
            <span slot="title">退款审核</span>
          </el-menu-item>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>系统设置</span>
            </template>
            <el-menu-item index="/config">
              <!-- <i class="el-icon-cpu"></i> -->
              <span slot="title">小程序配置</span>
            </el-menu-item>
            <el-menu-item index="/balancePay">
              <!-- <i class="el-icon-cpu"></i> -->
              <span slot="title">充值套餐管理</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="selectTypeBox">
            <!-- <span>xxxxx</span>
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                切换
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
                <el-dropdown-item>螺蛳粉</el-dropdown-item>
                <el-dropdown-item disabled>双皮奶</el-dropdown-item>
                <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
          <div class="tipsBox">
            <!-- <i class="el-icon-bell"></i> -->
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ user.auName }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="unlogin">退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { getMessageList } from "@/api/messages";
import { getRoomList } from "@/api/room";

export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      activeIndex: "/",
      user: JSON.parse(localStorage.getItem("user")),
      status: false,
      data: [],
      roomlist: [],
      timeout: null,
    };
  },
  created() {
    if (!this.user?.auId || this.user?.auPower !== 1) {
      localStorage.removeItem("user");
      // 跳转登录页
      this.$router.push("/login");
    }

    this.getRoomList();

    // 路由矫正导航栏
    if (this.$route.path == "/") {
      this.activeIndex = "/";
    } else {
      this.activeIndex = location.hash?.replace('#', '');
    }

    // 监听路由变化
    this.$watch(
      () => this.$route.path,
      (newVal, oldVal) => {
        this.activeIndex = newVal;
        if (newVal == "/") {
          this.activeIndex = "/";
        } else {
          this.activeIndex = location.hash?.replace('#', '');
        }
      }
    );

    this.getMessageList();
  },

  methods: {
    // 获取消息列表
    getMessageList() {
      if(this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      } 
      if(!this.user?.auId || this.user?.auPower !== 1) {
        return this.timeout = setTimeout(() => this.getMessageList(), 5000);
      }
      getMessageList().then((res) => {
        const data = res?.data || [];
        // console.log(res, "data");
        if (!this.status) {
          this.data = data;
          this.status = true;
          data.forEach((item, i) => {
            console.log(item);
            if (this.data[i] !== item) {
              this.getRoomInfo(item);
            }
          });
        } else {
          data.forEach((item, i) => {
            console.log(item);
            if (this.data[i] !== item) {
              this.getRoomInfo(item);
            }
          });
        }
      });

      this.timeout = setTimeout(() => this.getMessageList(), 5000);
    },

    getRoomInfo(id) {
      // console.log(id, "id");
      const room = this.roomlist.find((item) => item.roId == id);
      if (room) {
        this.$notify({
          title: "提示",
          message: `新的订单来了，房间：${room.roName}， 房间号：${room.roNumber} 已经被预定！`,
          type: "warning",
          duration: 0,
        });
      }
    },

    getRoomList() {
      const page = {
        currentPage: 1,
        conditionInt: 1,
      };
      getRoomList(page).then((res) => {
        console.log(res, 'res');
        if (res?.code == 206) {
          page.conditionInt = res.pageTotal;
          getRoomList(page).then((res) => {
            // console.log(res, 'res');
            this.roomlist = res?.data || [];
          });
        }
      });
    },
    unlogin() {
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.layout {
  height: 100%;
  .el-header,
  .el-footer {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: var(--theme-background);
    color: var(--text-color);
  }

  .el-aside {
    height: calc(100vh);
    background-color: var(--theme-background);
    color: var(--text-color);
    text-align: center;
    // LOGO
    .logoBox {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid var(--border-color);
      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
      span {
        font-size: 20px;
        font-weight: 600;
        color: var(--theme-color);
      }
    }
    // 导航栏
    .el-menu {
      margin: 0 10px;
      border: 0;
      text-align: left;
      .el-menu-item,
      .el-submenu {
        font-size: 16px;
        font-weight: 400;
        color: var(--tips-color);
        border-radius: 8px !important;
      }
      .is-active {
        background-color: var(--background) !important;
        color: var(--action--color) !important;
      }
      .el-menu-item [class^="el-icon-"] {
        margin-right: 8px;
      }
    }
  }

  .el-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .selectTypeBox {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 400;
        color: var(--text-color);
        margin-right: 8px;
      }
      .el-dropdown span {
        font-size: 14px;
        font-weight: 400;
        color: var(--theme-color);
      }
    }
    .tipsBox {
      display: flex;
      align-items: center;
      .el-icon-bell {
        font-size: 20px;
        // font-weight: 600;
      }
    }
  }

  .el-main {
    padding: 10px;
    height: calc(100vh - 60px);
    color: var(--text-color);
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
