import request from '@/utils/request'

// 获取房间列表
export function getRoomList(data) {
  return request({
    url: '/room/getRoomAdminList',
    method: 'post',
    data
  })
}

// 获取房间当天正在进行的单子
export function getRoomTodayOrders(data) {
  return request({
    url: '/makeRoom/getRoomUseThe',
    method: 'post',
    data
  })
}

// 更新房间
export function updateRoom(data) {
  return request({
    url: '/room/updateRoom',
    method: 'post',
    data
  })
}

// 管理员开房门
export function openRoomDoor(data) {
  return request({
    url: '/room/adminOpenDoor',
    method: 'post',
    data
  })
}

// 查询是否可续费
export function queryRoomCanRenew(roId, time) {
  return request({
    url: `/makeRoom/ContinueRoomIsNo/${ roId }/${ time }`,
    method: 'post',
  })
}

// 给房间续费
export function renewRoom(id, time, type = 4) {
  return request({
    url: `/makeRoom/ContinueRoom/${ id }/${ time }/${ type }`,
    method: 'post',
  })
}


// 预约房间
export function reserveRoom(data) {
  return request({
    url: '/makeRoom/adminMakeRoom',
    method: 'post',
    data
  })
}