import axios from 'axios'

// 封装axios
const request = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL, // 设置统一的请求路径前缀
  timeout: process.env.TIMEOUT, // 设置统一的超时时间
})
request.interceptors.request.use(
  config => {
    // 请求拦截器
    return config
  },
  error => {
    // 请求错误
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    // 响应拦截器
    return response.data;
  },
  error => {
    // 响应错误
    // return Promise.reject(error)
    return error.response?.data
  }
)

export default request